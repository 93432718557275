import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import useSubscription from '@util/hooks/useSubscription';
import { useInvoice } from '@util/providers/Invoice';

import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import Checkmark from '@images/checkmarkInverted.svg';
import ThankYouBody from '@components/ThankYou/thankyou';

export default function MyPlanModifyThankYouPage({ location }) {
  return (
    <ProtectedPage>
      <Rules validPurchase={location.state.validPurchase}>
        <PageContent />
      </Rules>
    </ProtectedPage>
  );
}

function PageContent() {
  const { t } = useTranslation();
  const { billingDetails, paymentDetails } = useSubscription();
  const { invoicePreview } = useInvoice();
  const isFutureAmendment = !invoicePreview?.item.price.prorated;

  return (
    <>
      <SEO title={t('page:thankYouForYourOrder')} />
      <PageHeader className="mb-4">
        {t('page:thankYouForYourOrder')}
        <img className="pl-3" src={Checkmark} alt="yes" />
      </PageHeader>

      {isFutureAmendment ? (
        <>
          <p>
            {/* prettier-ignore */}
            <Trans i18nKey="modify.futureAmendmentThankYou">
              These changes will take effect at the end of the current billing
              cycle. An invoice will be available once these changes are applied
              to your account. If you wish to cancel a pending change, please
              call 
              <a
                href={process.env.CONTACT_US}
                rel="noopener noreferrer"
                target="_blank"
              >
                customer support
              </a>
              .
            </Trans>
          </p>
          <div className="mt-4">
            <a className="btn btn-primary" href="/">
              {t('action.proceed')}
            </a>
          </div>
        </>
      ) : (
        <ThankYouBody
          billingDetails={billingDetails}
          paymentDetails={paymentDetails}
          amendment
        />
      )}
    </>
  );
}

function Rules({ children, validPurchase }) {
  const subscription = useSubscription();
  const { invoicePreview, invoiceNumber, invoiceId } = useInvoice();
  const homeRules =
    !subscription?.isSubscriber ||
    !invoicePreview ||
    !invoiceNumber ||
    !invoiceId ||
    !validPurchase;

  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}
